// region Usage Note
//    Use this file only to add modifications to the primeng theme that cannot be
//    achieved through use of the sass api. Use the _variables.scss file for
//    styling through the primeng sass api.
// region

.p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--color-neutral-50);
    width: 6px;
}

// These SCSS variables are used within the PrimeNG theme included via primeng/faro/variables
// We don't use these in our SCS components - we use CSS variables instead, see below

@import "primeng";
@import "~primeng/resources/primeng.min.css"; // TODO FL: Do we really need this import? Check if everything maybe even works without it...
@import "~primeicons/primeicons.css";

.pi-angle-left {
    transform: rotate(var(--facet-arrow-start-deg));
}

// *** TREE STYLING ***

.p-tree {
    border-radius: var(--zero-border-radius);
    border: unset;
    color: var(--color-neutral-80);
    padding: 0;
    height: 213px;
}

.p-tree-empty-message {
    padding: 10px 16px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color: var(--color-neutral-0);
    color: var(--color-neutral-80);
    box-shadow: unset;
    border-radius: var(--zero-border-radius);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight:hover {
    background-color: var(--color-neutral-15);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: var(--zero-border-radius);
    transition: none;
    padding: 0;
    height: 36px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: unset;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
    background-color: var(--color-neutral-15);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    color: var(--color-neutral-80) !important;
    box-shadow: unset !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:hover {
    color: var(--color-neutral-80) !important;
    background-color: transparent !important;
}

.p-treenode-content,
.p-treenode-children {
    position: relative;
}

.p-treenode-content::before,
.p-treenode-children::after {
    content: "";
    position: absolute;
}

.p-treenode-content::before {
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    height: 13px;
    width: 10px;
    left: 27px;
    top: 5px;
}

.p-tree-wrapper > .p-tree-container > .p-element > .p-treenode > .p-treenode-content::before {
    display: none;
}

// *** BUTTON STYLING ***
.p-button {
    height: var(--button-height);
    border-radius: var(--button-border-radius);
}

// *** DIALOG STYLING ***

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: var(--color-neutral-70);
}

.divider {
    height: 16px;
}

// *** INPUTSWITCH STYLING ***

.p-inputswitch {
    width: var(--switch-width);
    height: var(--swith-height);
}

.p-inputswitch .p-inputswitch-slider {
    background-color: var(--color-neutral-15);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
    background-color: var(--color-secondary-main) !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    background-color: var(--color-neutral-45);
    height: 24px;
    width: 24px;
    margin-top: -12px;
}

.p-inputswitch-checked > .p-inputswitch-slider:before {
    background-color: var(--color-neutral-5);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(28px);
}

// *** TABLE STYLING ***

tbody {
    vertical-align: top;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-sortable-column.p-highlight {
    background-color: var(--color-neutral-5);
    border-color: var(--color-neutral-20);
    color: var(--color-neutral-80);
    font-weight: 400;
}

.p-datatable .p-sortable-column:hover,
.p-datatable .p-sortable-column.p-highlight:hover {
    background-color: var(--table-hover) !important;
    color: var(--color-neutral-80);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--color-secondary-main);
}

.p-datatable .p-datatable-tbody > tr {
    height: var(--table-row-height);
}

.p-datatable .p-datatable-tbody > tr > td {
    border: unset;
    max-width: 250px;
    color: var(--color-neutral-80);
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
    background-color: var(--color-neutral-5);
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: var(--color-neutral-0);
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd):hover,
.p-datatable .p-datatable-tbody > tr:nth-child(even):hover {
    background-color: var(--table-hover) !important;
}

.p-table-icon-button {
    background-color: transparent;
    color: var(--color-neutral-70);
    border: unset;
}

.p-table-icon-button:focus,
.p-table-icon-button:hover {
    box-shadow: none;
    background-color: transparent;
    color: var(--color-neutral-70);
}

.p-table-action-button {
    background-color: var(--color-secondary-main);
    color: var(--color-neutral-0);
    border: unset;
    border-radius: var(--button-border-radius);
}

.p-table-action-button:focus {
    box-shadow: none;
    background: var(--color-secondary-main);
    color: var(--color-neutral-0);
}

.p-table-action-button:hover {
    box-shadow: none;
    background: var(--color-secondary-light) !important;
    color: var(--color-neutral-0);
}

// *** DATEPICKER STYLING ***

.p-datepicker table td > span.p-highlight {
    background-color: var(--color-secondary-main);
}

// *** OVERLAY PANEL STYLING ***

.p-overlaypanel .p-overlaypanel-close {
    background-color: var(--color-neutral-0);
    color: var(--color-neutral-70);
    top: 35px;
    right: 2rem;
}

.p-overlaypanel .p-overlaypanel-close:hover {
    background-color: var(--color-neutral-15) !important;
    color: var(--color-neutral-70) !important;
}

.p-overlaypanel .p-overlaypanel-close > .pi-times:before {
    content: "\e909" !important;
}

// *** DATEPICKER STYLING ***

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: var(--datepicker-color);
}

.p-datepicker .p-monthpicker .p-monthpicker-month:hover,
.p-datepicker .p-yearpicker .p-yearpicker-year:hover {
    color: var(--datepicker-hover-color);
}

.p-datepicker table td > span:hover {
    color: var(--datepicker-hover-color);
}

.p-datepicker .p-datepicker-header {
    background-color: var(--datepicker-background);
    color: var(--datepicker-color);
}

.p-datepicker {
    background-color: var(--datepicker-background);
    color: var(--datepicker-color);
    border: unset;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev {
    position: fixed;
    left: 70%;
    color: var(--next-prev-icons);
}

.p-datepicker .p-datepicker-header .p-datepicker-next {
    position: fixed;
    right: 15%;
    color: var(--next-prev-icons);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:hover {
    background-color: var(--color-neutral-15);
}

.p-datepicker-header .p-datepicker-title {
    margin: unset !important;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    background-color: var(--color-secondary-main);
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background-color: var(--color-secondary-main);
}

// *** FOCUS SHADOW FIXES STYLING ***

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight:focus,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight:focus,
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-selectbutton .p-button.p-highlight:focus,
.p-selectbutton .p-button:focus,
.p-inputswitch.p-focus .p-inputswitch-slider,
.p-dropdown.p-focus,
.p-datatable .p-sortable-column.p-highlight {
    box-shadow: none !important;
}

// *** HISTLIST STYLING ***

.search-select > .p-selectbutton .p-button {
    height: 30px;
    width: 90px;
    border-radius: var(--button-border-radius);
}

.facet-select-button > .p-selectbutton .p-button {
    height: 30px;
    width: 50%;
    border-radius: var(--button-border-radius);
}

.search-select > .p-selectbutton .p-button.p-highlight,
.facet-select-button > .p-selectbutton .p-button.p-highlight,
.hit-list-select > .p-selectbutton .p-button.p-highlight {
    color: var(--color-neutral-70);
    background-color: var(--color-neutral-15);
    border-color: #cac8bf;
}

.hit-list-select > .p-selectbutton .p-button {
    height: var(--button-height);
    width: 40px;
    padding: 12px;
    border-radius: var(--button-border-radius);
    border: unset;
}

.hit-list-dropdown-container {
    display: flex;
    align-items: center;
}

.hit-list-dropdown > .p-dropdown {
    width: 200px;
    height: var(--input-height);
    border-radius: var(--button-border-radius);
    margin-right: 15px;
}

.hit-list-dropdown > .p-dropdown:hover,
.hit-list-dropdown > .p-dropdown.p-focus {
    border-color: unset;
}

@keyframes p-progress-spinner-color {
    0% {
        stroke: var(--color-primary-main);
    }

    20% {
        stroke: var(--color-primary-main);
    }

    40% {
        stroke: var(--color-primary-main);
    }

    60% {
        stroke: var(--color-primary-main);
    }

    80% {
        stroke: var(--color-primary-main);
    }

    100% {
        stroke: var(--color-primary-main);
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: var(--color-secondary-main);
}

// *** CSS variables as used by PrimeNG ***
:root {
    // --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-a: #c523b8;
    --surface-b: #14eb26;
    --surface-c: #f17a0b;
    --surface-d: #df2fc1;
    --surface-e: #45f335;
    --surface-f: #cb18db;
    --text-color: #f019cc;
    --text-color-secondary: #e91fce;
    --primary-color: #f321e2;
    --primary-color-text: #f72cec;
    // --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    // TODO AK: Below is PSP specific, please move to PSP (add CSS variable)
    //--font-family: 'outfit', sans-serif;
    --surface-0: #b40e74;
    --surface-50: #c5159f;
    --surface-100: #491de6;
    --surface-200: #21f80e;
    --surface-300: #cf03f8;
    --surface-400: #f1430e;
    --surface-500: #ee0cdb;
    --surface-600: #d50ef0;
    --surface-700: #ee16ad;
    --surface-800: #f115f1;
    --surface-900: #e920bd;
    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};
    --surface-ground: #f71dd2;
    --surface-section: #f88f04;
    --surface-card: #f0790b;
    --surface-overlay: #e71fc6;
    --surface-border: #ec1af3;
    --surface-hover: #d834ee;
    --maskbg: #{$maskBg};
    --focus-ring: #{$focusShadow};
}

// region Usage Note
//    Includes all PrimeNG designer variables
// endregion

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';

// this overrides variable $focusShadow in variables/general
$focusShadow: 0 0 0 0.1rem #000;

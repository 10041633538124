// region Usage Note
//    Use this file for custom font style definitions.
// endregion

@font-face {
    font-family: "srf";
    font-style: normal;
    font-weight: 100;
    src: url("../assets/fonts/SRGSSRTypeText_W_Lt.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/SRGSSRTypeText_W_Rg.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts/SRGSSRTypeText_W_Md.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: italic;
    font-weight: 400;
    src: url("../assets/fonts/SRGSSRTypeText_W_It.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: normal;
    font-weight: 700;
    src: url("../assets/fonts/SRGSSRTypeText_W_Bd.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: italic;
    font-weight: bold 700;
    src: url("../assets/fonts/SRGSSRTypeText_W_BdIt.woff2") format("woff2");
}

@font-face {
    font-family: "srf";
    font-style: normal;
    font-weight: 900;
    src: url("../assets/fonts/SRGSSRTypeText_W_He.woff2") format("woff2");
}


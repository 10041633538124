.flowplayer * {
    color: white;
    background-color: transparent;
    border: none;
    -webkit-user-select: none
}

.flowplayer ul, .flowplayer li {
    list-style-type: none
}

.flowplayer [role="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

#fp-icons {
    display: none
}

.flowplayer {
    font-family: 'work sans', avenir, sans-serif;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #333;
    display: inline-block;
    position: relative;
    text-align: left;
    overflow: hidden;
    font-size: 16px;
    width: 100%;
    color: white
}

.flowplayer, .flowplayer * {
    box-sizing: border-box
}

.flowplayer.is-small {
    font-size: 14px
}

.flowplayer.is-tiny {
    font-size: 12px
}

.fp-engine, .fp-ui {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0
}

.fp-ui {
    justify-content: space-between;
    flex-direction: column;
    display: flex
}

.fp-header {
    align-items: center;
    display: flex;
    padding: 1em;
    z-index: 1;
    line-height: 1;
    min-height: 3.6em
}

.fp-header .fp-duration {
    background-color: rgba(0, 0, 0, 0.8);
    padding: .3rem .6rem;
    border-radius: 2px;
    display: none
}

.fp-logo {
    position: absolute;
    max-width: 20%;
    left: 2em
}

.fp-secondary {
    text-align: right;
    flex: 1
}

.fp-secondary > *:hover svg {
    transform: scale(1.05)
}

.fp-fullscreen, .fp-fullscreen-exit {
    cursor: pointer;
    width: 2em
}

.fp-fullscreen-exit {
    display: none
}

.fp-middle {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex: 1
}

.fp-footer {
    position: absolute;
    left: 2.2em;
    bottom: 2.2em;
    line-height: 1.5;
    font-size: 85%;
    max-width: 70%;
    max-height: 30%;
    z-index: 1;
    display: none;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.fp-controls .fp-timestamp, .fp-controls .fp-elapsed, .fp-controls .fp-duration, .fp-controls .fp-title, .fp-controls .fp-desc, .fp-footer .fp-timestamp, .fp-footer .fp-elapsed, .fp-footer .fp-duration, .fp-footer .fp-title, .fp-footer .fp-desc {
    text-shadow: 0 1px 2px black
}

.fp-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 110%;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis
}

.fp-desc {
    opacity: .8;
    max-height: 6em;
    overflow: hidden
}

.fp-time {
    text-align: center
}

.fp-controls {
    padding: .72em;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    text-align: right;
    display: flex;
    z-index: 1;
    height: 3.6em
}

.fp-controls > * {
    margin: 0 .45em
}

.fp-controls > :empty {
    display: none
}

.fp-timeline {
    background-color: rgba(255, 255, 255, 0.1);
    position: relative;
    cursor: col-resize;
    height: .9em;
    flex: 1
}

.fp-timeline:before {
    content: "";
    bottom: -.9em;
    top: -.9em;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1
}

.fp-timeline > * {
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    height: 100%
}

.flowplayer:not(.is-seeking) .fp-progress, .flowplayer:not(.is-seeking) .fp-buffer {
    transition: width .2s
}

.flowplayer:not(.is-starting) {
    background-size: 0 0
}

.fp-volumebtn {
    line-height: .5;
    width: .7em;
    cursor: pointer;
    margin-right: .9em
}

.fp-volumebtn:hover {
    transform: scale(1.1)
}

.fp-volumebtn + .fp-volume {
    margin-left: -.45em
}

.fp-volume {
    display: flex;
    height: .9em;
    width: 5em;
    cursor: pointer
}

.fp-tick {
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    margin-left: 4px;
    height: 100%;
    width: 100%
}

.fp-tick:hover {
    transform: scale(1.2)
}

.fp-timestamp {
    top: -.9em;
    font-weight: 400;
    background: none;
    font-size: 80%;
    line-height: 0
}

.fp-timestamp.with-thumbnail:hover {
    cursor: pointer
}

.fp-timestamp.with-thumbnail span {
    position: absolute;
    display: inline-block;
    top: .9em;
    left: .45em
}

.fp-error-details {
    white-space: pre;
    line-height: 0
}

.flowplayer svg {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4))
}

.fp-color {
    background-color: white
}

.fp-color-text {
    color: white
}

.fp-stroke {
    stroke: white;
    stroke-width: 4
}

.fp-thin-stroke {
    stroke: white;
    stroke-width: 2
}

.fp-fill {
    fill: white
}

.fp-rect {
    rx: 1;
    ry: 1
}

.fp-switch {
    display: none
}

.use-play-1 .fp-play-1, .use-play-2 .fp-play-2, .use-play-3 .fp-play-3 {
    display: inline
}

.use-thin-controlbar .fp-timeline, .use-drag-handle .fp-timeline {
    transition: height .2s;
    height: 2px
}

.use-thin-controlbar .fp-controls:hover .fp-timeline, .use-drag-handle .fp-controls:hover .fp-timeline {
    height: .9em
}

.use-drag-handle .fp-dragger {
    display: inline-block;
    transition: transform .2s;
    border-radius: 3em;
    width: 1em;
    height: 1em;
    position: relative;
    top: -.44em
}

.use-drag-handle .fp-controls:hover .fp-timeline {
    height: .3em
}

.use-drag-handle .fp-controls:hover .fp-dragger {
    transform: translateY(1px) scale(1.3)
}

.use-drag-handle .fp-timestamp {
    margin-top: -1.8em;
    margin-left: .4rem
}

.use-muted-autoplay .fp-secondary {
    display: none
}

.use-muted-autoplay .fp-controls {
    justify-content: flex-end;
    opacity: 1
}

.use-muted-autoplay .fp-controls > * {
    display: none
}

.use-muted-autoplay .fp-volumebtn {
    margin: 0 1.8em .45em 0;
    display: inline
}

.logo-on-right .fp-header {
    flex-direction: row-reverse;
    position: relative
}

.logo-on-right .fp-secondary {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0.7em;
    top: 0.7em
}

.logo-on-right .fp-secondary > * {
    margin: 0.3em
}

.logo-on-right .fp-primary .fp-logo {
    right: 1em;
    left: auto
}

.no-mute > .fp-ui .fp-volumebtn {
    display: none
}

.no-fullscreen .fp-secondary {
    display: none
}

.no-volume > .fp-ui .fp-volume {
    display: none
}

.poor-flex .fp-ui {
    display: block
}

.poor-flex .fp-ui > * {
    position: absolute;
    width: 100%
}

.poor-flex .fp-header {
    top: .45em;
    height: 3em
}

.poor-flex .fp-middle {
    top: 50px;
    height: 65%
}

.poor-flex .fp-controls {
    height: 3em;
    bottom: 0
}

.has-poster.is-starting .fp-engine {
    display: none
}

.is-small .fp-play, .is-small .fp-pause {
    width: 4em
}

.is-small .fp-header {
    padding: 0.5em
}

.is-tiny .fp-play, .is-tiny .fp-pause {
    width: 2.6em
}

.is-tiny .fp-duration {
    display: none
}

.is-tiny .fp-timeline {
    margin: 0 .45em
}

.is-tiny .fp-footer {
    left: 1.2em;
    bottom: 1.2em;
    font-size: 65%
}

.fp-switch {
    will-change: transform, opacity;
    transition: transform .2s;
    transition-timing-function: ease-out;
    margin-top: -.9em;
    transform: scale(0.5);
    opacity: 0
}

.is-waiting .fp-switch, .is-seeking .fp-switch {
    transition: none;
    transform: none;
    opacity: 0
}

.fp-switch > * {
    width: 5em
}

.is-toggling:not(.is-seeking) .fp-switch {
    transform: scale(1);
    opacity: 1
}

.is-small.is-toggling:not(.is-seeking) .fp-switch {
    transform: scale(0.85)
}

.is-tiny.is-toggling:not(.is-seeking) .fp-switch {
    transform: scale(0.7)
}

.is-starting:not(.is-waiting) .fp-switch {
    transform: scale(1);
    opacity: 1
}

.is-small.is-starting:not(.is-waiting) .fp-switch {
    transform: scale(0.85)
}

.is-tiny.is-starting:not(.is-waiting) .fp-switch {
    transform: scale(0.7)
}

.is-touched:not(.is-seeking) .fp-switch {
    transform: scale(1);
    opacity: 1
}

.is-small.is-touched:not(.is-seeking) .fp-switch {
    transform: scale(0.85)
}

.is-tiny.is-touched:not(.is-seeking) .fp-switch {
    transform: scale(0.7)
}

.is-ended .fp-switch {
    transform: scale(1);
    opacity: 1
}

.is-small.is-ended .fp-switch {
    transform: scale(0.85)
}

.is-tiny.is-ended .fp-switch {
    transform: scale(0.7)
}

.is-touch-device .is-playing:not(.is-touched) .is-seeking .fp-pause, .is-touch-device .is-paused:not(.is-touched) .is-seeking .fp-play {
    width: 0;
    height: 0
}

.fp-small-switch {
    margin: 0;
    height: 1.8em
}

.fp-small-switch > * {
    width: 1.8em;
    display: none;
    cursor: pointer
}

.is-playing .fp-small-pause, .is-paused .fp-small-play {
    display: inline
}

.is-playing.is-touched .fp-play {
    width: 0;
    height: 0
}

.is-paused.is-touched .fp-pause {
    width: 0;
    height: 0
}

.is-paused:not(.is-touched):not(.is-starting) .fp-play {
    width: 0;
    height: 0
}

.is-playing:not(.is-touched) .fp-pause {
    width: 0;
    height: 0
}

.is-ended .fp-pause {
    width: 0;
    height: 0
}

.no-autoplay {
    background-color: #262626
}

.is-starting .fp-controls {
    visibility: hidden
}

.is-starting .fp-pause {
    width: 0
}

.is-starting .fp-play {
    width: 5em;
    height: 5em
}

.is-starting .fp-header .fp-duration {
    display: inline-block
}

.is-starting .fp-footer {
    display: block
}

.is-starting .fp-fullscreen {
    visibility: hidden
}

.is-starting .fp-header > :empty {
    display: none
}

.is-seamless .fp-pause, .is-seamless .fp-play, .is-autoplay.is-starting .fp-pause, .is-autoplay.is-starting .fp-play, .is-livecountdown .fp-pause, .is-livecountdown .fp-play {
    width: 0;
    height: 0
}

.fp-togglable {
    transform: opacity .4s;
    will-change: opacity;
    opacity: 0
}

.is-hovered .fp-togglable, .is-chromecast-playing .fp-togglable, .is-paused .fp-togglable, .is-touched .fp-togglable {
    opacity: 1
}

.is-hovered .fp-ui, .is-chromecast-playing .fp-ui, .is-paused .fp-ui, .is-touched .fp-ui {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 15%), linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 20%)
}

.flowplayer.is-playing:not(.is-hovered):not(.is-chromecast-playing) * {
    cursor: none
}

.flowplayer {
    transition: filter .5s
}

.is-loading-3 {
    filter: blur(9px)
}

.is-loading-2 {
    filter: blur(6px)
}

.is-loading-1 {
    filter: blur(3px)
}

.is-muted .fp-volume {
    visibility: hidden
}

.is-muted .fp-volumebtn {
    white-space: nowrap
}

.is-muted .fp-volumebtn:after {
    content: '×';
    font-weight: 100;
    font-size: 170%;
    line-height: 0
}

.is-fullscreen:not(.is-starting) {
    background-image: none !important
}

.is-fullscreen {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    z-index: 99999 !important;
    border-radius: 0px !important;
    background-color: #262626
}

.is-fullscreen .fp-fullscreen {
    display: none
}

.is-fullscreen .fp-fullscreen-exit {
    display: inline-block
}

.fp-error {
    text-shadow: 0 0 1px black;
    text-align: center;
    height: 70%
}

.is-error .fp-togglable, .is-error .fp-middle {
    display: none
}

@keyframes waiting {
    0% {
        transform: scale(1)
    }
    20% {
        transform: scale(1, 2)
    }
    40% {
        transform: scale(1)
    }
}

.fp-wait {
    transition: opacity .3s .1s;
    position: absolute;
    opacity: 0;
    height: 2em
}

.is-waiting:not(.is-toggling):not(.is-livecountdown) .fp-wait, .is-seeking:not(.is-toggling) .fp-wait {
    opacity: 1
}

.is-waiting .fp-wait b, .is-seeking .fp-wait b {
    animation: waiting 1s ease-in-out infinite
}

.fp-wait b {
    box-shadow: 0 0 .5rem #222;
    background-color: white;
    display: inline-block;
    border-radius: .1em;
    margin: 0 .2em;
    height: 2em;
    width: .4em
}

.fp-wait b:nth-child(1) {
    animation-delay: 0
}

.fp-wait b:nth-child(2) {
    animation-delay: .1s
}

.fp-wait b:nth-child(3) {
    animation-delay: .15s
}

.fp-wait b:nth-child(4) {
    animation-delay: .25s
}

.fp-live-status {
    display: none;
    margin: 0 15px 0 7px
}

.fp-live-status .fp-live-indicator {
    border-radius: 12px;
    margin: 2px 5px 0;
    display: block;
    float: left;
    height: 12px;
    width: 12px;
    content: ""
}

.fp-live-status .fp-live-text {
    float: right;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 85%
}

.is-live .fp-live-status {
    display: inline;
    cursor: pointer
}

.is-live .fp-elapsed, .is-live .fp-buffer {
    display: none
}

.is-live .fp-progress {
    background-color: rgba(255, 255, 255, 0.1)
}

.is-live .fp-hover, .is-live .fp-progress {
    right: 0
}

.is-live .fp-duration {
    min-width: 2.8em
}

.is-live-seeked .fp-progress {
    background-color: white
}

.is-live-seeked .fp-live-status:hover {
    cursor: pointer
}

.is-live-seeked .fp-live-indicator {
    background-color: rgba(204, 204, 204, 0.5)
}

.no-timeline .fp-timeline {
    visibility: hidden
}

.fp-livecountdown {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), transparent 33%);
    color: #fff;
    text-transform: uppercase;
    text-align: center
}

.fp-livecountdown p {
    margin: 0;
    padding: 0;
    padding-bottom: .45em
}

.fp-livecountdown .fp-inner {
    display: inline-block;
    padding-top: 3.6em;
    font-size: 85%;
    text-align: left;
    min-width: 15em;
    font-weight: 500;
    letter-spacing: 1px
}

.fp-livecountdown .fp-countdown {
    font-size: 230%;
    text-transform: none;
    font-weight: 300
}

.fp-menu-container, .fp-menu-container:before {
    font-weight: 600
}

.fp-menu-container:hover {
    cursor: pointer
}

.fp-menu > h3:empty {
    display: none
}

.fp-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;
    position: absolute;
    background: #010000;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0.2em;
    margin-bottom: .9em;
    max-height: 80%
}

.fp-menu::-webkit-scrollbar {
    width: 0.2em
}

.fp-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
}

.fp-menu::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey
}

.fp-menu.fp-on {
    display: block;
    bottom: 2.52em;
    right: .9em
}

.fp-menu h3, .fp-menu li {
    padding: .9em 2.7em;
    margin: 0;
    text-align: center;
    font-size: .9em;
    font-weight: 700;
    color: #FDFDFD
}

.fp-menu h3 {
    text-transform: uppercase;
    font-size: .99em
}

.fp-invis {
    display: none
}

.fp-menu h3 {
    border-bottom: 1px solid #232323;
    letter-spacing: 1px
}

.fp-menu .fp-close {
    font-size: 150%;
    position: absolute;
    right: .18em;
    top: 0em;
    cursor: pointer;
    display: none
}

.fp-menu li.fp-on {
    background: #373737
}

.fp-menu li:hover {
    background: #505050;
    font-weight: 900
}

.fp-menu li.on {
    background: #1D1D1D
}

.fp-menu li svg {
    display: inline-block;
    height: 1.08em;
    margin-right: .9em;
    vertical-align: top;
    max-width: 2.7em
}

.fp-menu.fp-icons h3, .fp-menu.fp-icons li {
    text-align: left;
    white-space: nowrap
}

.is-small .fp-menu-container .fp-menu {
    position: absolute;
    font-size: 110%;
    border-radius: 0
}

.is-small .fp-menu-container .fp-menu h3 {
    font-size: .9em;
    min-width: 100%;
    padding-bottom: .45em
}

.is-small .fp-menu-container .fp-menu .fp-close {
    display: block
}

.is-small .fp-menu-container .fp-menu .fp-on {
    display: flex;
    flex-flow: column wrap
}

.is-small:not(.is-share-menu) .fp-menu-container .fp-menu {
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    z-index: 9999;
    max-height: 100%;
    height: 100%;
    margin-bottom: 0
}

.is-small:not(.is-share-menu) .fp-menu-container .fp-menu li {
    padding: .45em .27em
}

.is-starting .fp-captions {
    display: none
}

.fp-captions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: .9em
}

.is-hovered .fp-captions, .is-chromecast-playing .fp-captions, .is-touched .fp-captions, .is-paused .fp-captions {
    bottom: 3.2em
}

.fp-captions:hover {
    cursor: pointer
}

.fp-captions pre {
    padding: .45em .9em;
    margin: 0;
    background-color: rgba(1, 0, 0, 0.4)
}

.is-interstitial .fp-middle > :not(.fp-interstitial) {
    display: none
}

.is-interstitial .fp-interstitial {
    padding: 0em 4em;
    display: flex;
    flex-direction: row
}

.is-interstitial .fp-ui {
    background: rgba(0, 0, 0, 0.4)
}

.is-interstitial .fp-footer, .is-interstitial .fp-header .fp-duration {
    display: none
}

.use-play-1 .fp-interstitial .fp-play-1 .fp-play, .use-play-2 .fp-interstitial .fp-play-2 .fp-play, .use-play-3 .fp-interstitial .fp-play-3 .fp-play {
    width: 5em !important;
    height: 5em !important
}

.fp-interstitial {
    display: none;
    font-weight: 500;
    letter-spacing: 1px;
    overflow: hidden;
    width: 100%;
    flex: 1
}

.fp-interstitial .fp-play {
    width: 0;
    height: 0
}

.fp-interstitial .fp-pause {
    display: none !important
}

.fp-interstitial > div {
    padding: 0.5em
}

.fp-interstitial .up-next {
    margin-bottom: 0.5em
}

.fp-interstitial .up-next span {
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase
}

.fp-interstitial .title {
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: 0.90em
}

.fp-interstitial .description {
    font-weight: 500;
    font-size: 0.85em
}

.fp-interstitial .countdown {
    height: 1px;
    margin-top: 0.5em;
    width: 100%
}

.fp-interstitial .cancel {
    text-transform: capitalize;
    margin-top: 1em;
    display: block
}

.fp-interstitial .cancel:hover {
    color: #f0f0f0
}

.fp-interstitial .cancel span {
    font-size: 0.7em;
    text-transform: capitalize;
    color: #ccc
}

.fp-interstitial .right {
    display: flex;
    flex-basis: 0
}

.fp-interstitial .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-height: 61.803%
}

.fp-interstitial .right {
    flex-grow: 1;
    flex-direction: column;
    text-align: left;
    padding: 0 2em;
    font-size: 1.1em
}

.fp-interstitial.is-autoplay .countdown {
    width: 0%
}

.fp-playlist-controls .fp-play-1, .fp-playlist-controls .fp-play-2, .fp-playlist-controls .fp-play-3 {
    display: none
}

.fp-playlist-controls svg {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
    height: 0;
    width: 0
}

.fp-playlist-controls.use-play-1 .fp-play-1 {
    display: inline
}

.fp-playlist-controls.use-play-2 .fp-play-2 {
    display: inline
}

.fp-playlist-controls.use-play-3 .fp-play-3 {
    display: inline
}

.fp-playlist-controls {
    background: #333;
    color: #f1f1f1
}

.fp-playlist-controls ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column
}

.fp-playlist-controls li {
    cursor: pointer;
    margin: 0;
    display: flex;
    flex-direction: row;
    min-height: 5em;
    align-items: center
}

.fp-playlist-controls li .fp-color {
    transition: width 0.3s ease-out;
    height: 100%
}

.fp-playlist-controls li:not(:last-child) {
    border-bottom: 1px solid #3d3d3d
}

.fp-playlist-controls li:hover .fp-color, .fp-playlist-controls li.is-current .fp-color {
    width: 0.3em
}

.fp-playlist-controls li:hover:not(.is-current) {
    background: #4d4d4d
}

.fp-playlist-controls li.is-current {
    background: #404040
}

.fp-playlist-controls .video-info {
    padding: 1em;
    flex-grow: 4
}

.fp-playlist-controls .video-info .title {
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 500;
    margin: 0 0 0.5em 0
}

.fp-playlist-controls .video-info .description {
    font-size: 0.9em
}

.fp-playlist-controls .video-buttons {
    margin-right: 0.5em;
    padding: 1em;
    width: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    min-height: 66%
}

.fp-playlist-controls li.is-playing .fp-pause {
    height: 2em;
    width: 2em
}

.fp-playlist-controls li.is-queued .fp-play, .fp-playlist-controls li.is-paused .fp-play {
    height: 2em;
    width: 2em
}

.is-playlist .fp-playlist-prev-preview, .is-playlist .fp-playlist-next-preview {
    display: block;
    background-repeat: no-repeat;
    background-color: black;
    background-position: center;
    background-size: contain;
    position: relative;
    top: -2em;
    width: 0;
    height: 0;
    transition: width 200ms ease-in-out, height 200ms ease-in-out, top 200ms ease-in-out
}

.is-playlist .fp-playlist-prev-preview {
    left: 0.5em
}

.is-playlist .fp-playlist-next-preview {
    left: 6.5em
}

.is-playlist .fp-skip-prev {
    display: inline;
    width: 1.2em;
    margin-bottom: 0.3em;
    margin-left: 0.5em;
    margin-right: 1.5em;
    fill: white
}

.is-playlist .fp-skip-prev:hover ~ .fp-playlist-prev-preview {
    width: 8em;
    height: 6em;
    top: -9em
}

.is-playlist .fp-skip-next {
    display: inline;
    width: 1.2em;
    margin-bottom: 0.3em;
    margin-left: 1.5em;
    margin-right: 1em;
    fill: white
}

.is-playlist .fp-skip-next:hover ~ .fp-playlist-next-preview {
    width: 8em;
    height: 6em;
    top: -9em
}

.is-prev-button-disabled .fp-skip-prev {
    opacity: 0.5;
    cursor: default
}

.is-prev-button-disabled .fp-skip-prev ~ .fp-playlist-prev-preview {
    display: none !important
}

.is-next-button-disabled .fp-skip-next {
    opacity: 0.5;
    cursor: default
}

.is-next-button-disabled .fp-skip-next ~ .fp-playlist-next-preview {
    display: none !important
}

.flowplayer-fatal {
    background-color: rgba(38, 38, 38, 0.9);
    color: #a6a6a6;
    text-align: center;
    padding: 8em 3em 3em 3em
}

.flowplayer-fatal h2 {
    color: #fff
}

.flowplayer-fatal .troubleshoot {
    display: block;
    border: 1px solid #a6a6a6;
    width: 10em;
    margin: 1em auto;
    color: #fff;
    padding: 0.5em;
    border-radius: 0.2em;
    text-decoration: none;
    margin-bottom: 8em
}

.flowplayer-fatal img {
    height: 1em
}

.fp-header .fp-share-menu {
    float: left
}

.fp-header .fp-share-menu .fp-share {
    width: .9em
}

.is-starting .fp-share {
    display: none
}

.is-small.is-share-menu .fp-controls {
    display: none !important
}

.is-small .fp-share-menu li {
    padding: .27em;
    padding-left: 1.62em
}

.is-small.is-share-menu:not(.logo-on-right) .fp-menu {
    left: 0 !important;
    top: 0 !important
}

.fp-share-menu.webshare-enabled {
    display: inline-block
}

.fp-message {
    position: absolute;
    display: block;
    top: 3.6em;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    background-color: transparent;
    color: white;
    z-index: 10
}

.fp-message.fp-shown {
    max-height: 3em
}

.fp-message p {
    margin: 1em;
    text-align: center;
    text-shadow: 0 1px 2px black
}

.is-small .fp-message {
    top: 2.7em
}

.is-small .fp-message.fp-shown {
    max-height: 3em
}

.is-small .fp-message p {
    margin: .5em
}

.is-tiny .fp-message {
    top: 1.8em
}

.is-tiny .fp-message.fp-shown {
    max-height: 2em
}

.is-tiny .fp-message p {
    margin: .25em
}

.fp-airplay {
    width: 0
}

.is-airplay-available .fp-airplay {
    width: 2em;
    height: 1.8em;
    margin-right: 0.6em;
    padding-top: 0.2em;
    fill: white
}

.is-starting .fp-airplay {
    width: 0
}

.fp-btns:focus .is-accessibility, .fp-middle:focus .is-accessibility .fp-switch, .fp-menu li:focus .is-accessibility {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color
}

.fp-middle:focus .is-accessibility {
    outline: none
}

.is-accessibility a:focus > .fp-logo {
    background-color: gray
}

.flowplayer:not(.is-accessibility) * {
    outline: none
}

.fp-endscreen {
    display: none;
    position: absolute;
    top: 0
}

.is-endscreen .fp-endscreen {
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 4em;
    height: 100%;
    width: 100%;
    background: black
}

.is-endscreen .fp-endscreen-cells {
    display: inline;
    margin: auto;
    width: 100%;
    height: 100%
}

.is-endscreen .fp-endscreen-cell {
    display: inline-block;
    background: black;
    padding: 0.1em;
    float: left;
    overflow: hidden;
    width: 25%;
    height: 33.3%
}

.is-endscreen .fp-endscreen-cell-thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%
}

.is-endscreen .fp-endscreen-cell-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.4em;
    float: none;
    overflow: hidden;
    text-align: left
}

.is-endscreen .fp-small-play {
    display: inline
}

.is-small .fp-endscreen {
    padding: 1.5em;
    font-size: small
}

.is-small .fp-endscreen-cell {
    width: 33.3%;
    height: 50%
}

.fp-endscreen-cell:hover {
    transition: transform .2s ease-in-out;
    transform: scale(1.1)
}

.fp-secondary .fp-cast-button {
    height: 1.6em;
    width: 1.8em;
    display: inline-block;
    cursor: pointer;
    transition: transform 0.2s ease-in;
    margin-left: 0.2em;
    margin-right: 0.2em
}

.fp-secondary .fp-cast-button:hover {
    transform: scale(1.1)
}

.fp-secondary .fp-cast-button google-cast-launcher {
    --disconnected-color: #fff
}

.fp-chromecast {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    padding-top: 3em;
    font-size: 200%;
    background-size: cover;
    text-shadow: 0 1px 2px black
}

.is-chromecast-playing .fp-fullscreen, .is-chromecast-playing .fp-fullscreen-exit, .is-chromecast-playing .fp-volume, .is-chromecast-playing .fp-volumebtn {
    display: none
}

.is-chromecast-playing .fp-chromecast {
    display: block
}

.is-chromecast-playing.no-chromecast-controls .fp-controls {
    display: none !important
}

.flowplayer.is-flashls .fp-flashls {
    position: absolute
}

.flowplayer.is-flashls .fp-flashls.is-initialising {
    z-index: 9999
}

.flowplayer.is-audio-player {
    background-size: cover
}

.flowplayer.is-audio-player .fp-engine {
    display: none
}

.flowplayer.is-audio-player .fp-fullscreen {
    display: none
}

.flowplayer.is-audio-player.is-paused .fp-togglable, .flowplayer.is-audio-player.is-playing .fp-togglable {
    opacity: 1
}

.flowplayer.is-audio-player.show-controls-only {
    height: 3.6em
}

.flowplayer.is-audio-player.show-controls-only.is-paused .fp-header, .flowplayer.is-audio-player.show-controls-only.is-paused .fp-middle, .flowplayer.is-audio-player.show-controls-only.is-playing .fp-header, .flowplayer.is-audio-player.show-controls-only.is-playing .fp-middle {
    display: none
}

.flowplayer.is-audio-player.show-controls-only.is-paused .fp-controls, .flowplayer.is-audio-player.show-controls-only.is-playing .fp-controls {
    height: 100%
}

.flowplayer.is-audio-player.show-controls-only.is-starting {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: center
}

.flowplayer.is-audio-player.show-controls-only.is-starting video {
    display: none
}

.flowplayer.is-audio-player.show-controls-only.is-starting .fp-ui, .flowplayer.is-audio-player.show-controls-only.is-starting .fp-footer {
    position: relative;
    left: auto;
    bottom: auto;
    right: auto;
    max-width: none;
    width: auto
}

.flowplayer.is-audio-player.show-controls-only.is-starting .fp-footer {
    flex-grow: 1
}

.flowplayer.is-audio-player.show-controls-only.is-starting .fp-ui .fp-header, .flowplayer.is-audio-player.show-controls-only.is-starting .fp-ui .fp-controls {
    display: none
}

.flowplayer.is-audio-player.show-controls-only.is-starting .fp-ui .fp-middle {
    max-height: 3.6em
}

.flowplayer.is-audio-player.show-controls-only.is-starting .fp-ui .fp-middle .fp-switch {
    margin-top: 0.2em;
    transform: scale(0.6)
}

#flowplayer-floating-placeholder {
    background-color: rgba(38, 38, 38, 0.9);
    color: #a6a6a6
}

#flowplayer-floating-placeholder p {
    color: #fff;
    display: block;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    padding: 3em 0 0 0;
    font-size: 1.2em;
    font-weight: 600;
    text-decoration: none
}

body > #flowplayer-floating-container {
    max-width: 33%;
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    bottom: 1em;
    left: 1em
}

@media screen and (orientation: portrait) {
    body > #flowplayer-floating-container {
        bottom: 0;
        left: 0;
        right: 0;
        max-width: none
    }
}

.no-standard-controls .fp-controls, .no-standard-controls .fp-header > * {
    display: none
}

.fp-exit-float {
    display: none
}

.fp-exit-float .fp-close:hover {
    transform: scale(1.1)
}

.fp-mute-float {
    display: none
}

.fp-mute-float .fp-mute {
    display: none
}

.fp-mute-float .fp-unmute {
    display: block
}

.fp-mute-float .fp-mute:hover, .fp-mute-float .fp-unmute:hover {
    transform: scale(1.1)
}

.is-muted .fp-mute-float .fp-mute {
    display: block
}

.is-muted .fp-mute-float .fp-unmute {
    display: none
}

.is-popped-out .fp-volume, .is-popped-out .fp-volumebtn {
    display: none
}

.is-popped-out .fp-exit-float, .is-popped-out .fp-mute-float {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;
    width: 3.15em;
    height: 2.925em;
    background-color: #0005;
    border-radius: 100%
}

@media screen and (orientation: portrait) {
    .is-popped-out .fp-exit-float, .is-popped-out .fp-mute-float {
        right: 1vw;
        width: 12vw;
        height: 12vw
    }
}

.is-popped-out .fp-mute-float {
    left: 1em
}

.fp-float-player-icons {
    fill: #fff;
    stroke: #fff;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round
}

.no-title .fp-title {
    display: none
}

.no-description .fp-desc {
    display: none
}

.no-controls .fp-controls {
    display: none
}

.no-controls .fp-middle {
    padding-bottom: 3.6em
}

.no-duration .fp-header .fp-duration {
    display: none
}

.no-header .fp-header {
    display: none
}

.no-header .fp-middle {
    padding-top: 3.6em
}

// region Usage Note
//    Use this file to add reusable and global styles.
//    Define variables in _variables.scss instead.
// endregion
@use "reset" as *;
@import "srf-style";
@import "fonts";

// Include flowplayer styles
@import "../../lib/flowplayer/style/flowplayer.css";

body {
    margin: 0;
    color: var(--font-color-contrast);
    background: var(--color-neutral-90);
    font-family: var(--font-family) !important;
    font-weight: 400;
    height: 100vh;
    height: var(--app-height);
    overflow: hidden;
}

.secondary-text {
    color: var(--color-neutral-70);
}

button,
a {
    font-family: inherit;
    color: var(--color-secondary-main);
    cursor: pointer;
}

h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}

.common_highlight {
    background-color: var(--color-hightlight);
    border-radius: 2px;
}

.p-component {
    font-family: var(--font-family) !important;
}

.search-select > .p-selectbutton .p-button {
    justify-content: center !important;
}


.p-datatable-wrapper,
.p-tree-wrapper,
.p-dialog-content,
.information-container,
faro-list-facet > faro-facet-panel > .panel > .content > div > .list,
.player-split-part,
.light-table,
.p-dropdown-items-wrapper,
.shopping-cart-search,
.active-shopping-cart-export,
.dialog-content > .tree-container,
.light-table-container,
.media-container,
.p-menu {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        margin-left: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-neutral-50);
        border-radius: 5px;
        width: 6px;
    }
}
